import { LOCALES } from "../locales";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  [LOCALES.SPANISH]: {
    navbarHome: "Inicio",
    navbarAbout : "Sobre Mí",
    navbarProjects: "Proyectos & Trabajo",
    navbarContact: "Contacto",
    mainHi: "Hola!",
    mainIm: "Soy ",
    mainDescription: "descripcion",
    scroll: "Sigue bajando",
    aLittleMore: "UN POCO MAS SOBRE",
    me:"MÍ",
    hiPresentation:"Hola, soy",
    hiPresentationP2:" Gusto en conocerte :D. Soy desarrollador fullstack",
    aboutPresentation: "Un día encontré un lugar conocido como internet. Ahora me encanta programar y crear cosas que existen en él.",
    descriptionPresentation: "Persona organizada, solucionador de problemas, trabajador en equipo. Mi enfoque principal es crear productos y experiencias digitales accesibles e inclusivas. Teniendo en cuenta tanto el espectro del front-end como el del back-end.",
    technicalPresentationP1: "Me gusta mucho trabajar con lenguajes de programación como ",
    technicalPresentationP2:" Pero siempre estoy deseando ",
    technicalPresentationP3: " nuevos.",
    technicalLearn:"aprender",
    technicalAnd:" y ",
    personalPresentation:"Fan de los libros, el cine, la animación y los videojuegos.",
    someTechPresentation: "Algunas tecnologías con las que he trabajado recientemente",
    getGetInTouch: "PONTE ",
    intouchInTouch: " EN CONTACTO",
    pt1InTouch: "Mi bandeja de entrada siempre esta abierta. Bien sea para una pregunta, para un proyecto. Tal vez solo para saludar, recomiendame una película. Haré mi mayor esfuerzo para contestar lo más pronto posible.",
    pt2InTouch: "Sientete libre de contactarme.",
    sayHelloInTouch:"Salúdame :D",
    errorMsg: "Página no encontrada",
    errorBtn: "Ir al inicio",
    projects: "DONDE HE ",
    built:"TRABAJADO",
    oneLast: "UNA ULTIMA ",
    thing: "PREGUNTA",
    projectsInWork: "Esta zona esta en desarrollo.",

    uniandes: "Universidad de los Andes",
    teachingAsistant: "Monitor",
    intern:"Practicante de desarrollo de software",
    heinsohn:"Heinsohn",
    fullstack:"Desarrollador Full Stack",
    wonder:"WONDER! XR",
    founderDev: "Cofundador y Desarrollador Full Stack",
    daddo:"DADDO Software",
    JulyDosUno:"Julio 2021",
    DecemberDosUno:"Diciembre 2021",
    MayDosDos: "Mayo 2022",
    JulyDosDos: "Julio 2022",
    Present:"Presente",
    2019:"2019",
    2022:"2022",
    sistrans:"Monitor de Sistemas Transaccionales",
    lym:"Monitor de Lenguajes y Máquinas",
    apo:"Monitor de Algorítmica y Programación orientada a objetos",
    hei1:"Implementación de protocolos DevOps para mantener la integridad de las bases de datos e información",
    hei2:"Desarrollo y mantenimiento de código bash para mantener los archivos sin formato actualizados en las instancias de la base de datos",
    hei3:"Desarrollo de scripts para crear maquinas virtuales, aprovisionarlas y adecuarlas para uso como backup de base de datos",
    hei4:"Adecuar codigo en java para generar sentencias SQL válidas para la cargar la base de datos",
    wdr1:"Desarrollar y mantener el Backend para la web y juego de unity",
    wdr2:"Desarrollar sitio web de WONDER XR",
    wdr3:"Integración de Juego con el Backend",
    ddo1:"Desarrollo Fullstack",
    ddo2:"Cofundador"



  },
};
